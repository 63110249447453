<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Danh sách giao dịch</h6></b-col>
                  <b-col class="text-right">
                    <a href="/#/reconcile/reconciles" class="btn btn-success">
                      <i class="fa fa-list"></i>
                      Danh sách
                    </a>
                  </b-col>
                </b-row>
              </template>
              <b-table
                :fields="fields"
                :items="items"
                details-td-class="datatable-cell"
                hover
                table-class="datatable-table"
                tbody-tr-class="datatable-row"
                thead-class="datatable-head"
                thead-tr-class="datatable-row"
              ></b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="genLink"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import CmsRepository from "@/core/repositories/cmsRepository";

export default {
  name: "Detail",
  mixins: [Common],
  components: {},
  data() {
    return {
      filter: {},
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "payment_no",
          label: "Mã giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "partner_code",
          label: "Mã giao dịch đối tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "reason",
          label: "Lý do",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      id: this.$route.query.id,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giao dịch không khớp" },
    ]);
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await CmsRepository.detailReconcile(
          this.id,
          this.filter
        );

        this.$bus.$emit("show-loading", false);
        let body = response.data.data;

        this.items = body.data;
        this.paginate.total = body.total;
        this.paginate.totalPage = body.last_page;
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    genLink(pageNum) {
      return `?id=${this.id}&page=${pageNum}`;
    }
  },
};
</script>
